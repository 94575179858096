import { graphql, StaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { BaseSyntheticEvent, FC } from "react";
import styled from "styled-components";
import { imageUrlFor } from "../lib/image-url";

interface IHeroProps {
    image?: IGatsbyImageData | undefined;
    url?: string;
}

const Hero: FC = () => {
    const handleClick = (e: BaseSyntheticEvent) => {
        window.dataLayer.push({
            event: "ctaClick",
            location: "hero",
            linkTitle: e.target.hash,
        });
    };

    return (
        <StaticQuery
            query={graphql`
                query heroComponent {
                    sanityHeroComponent(_id: { eq: "heroComponent" }) {
                        heroMessage
                        heroText
                        buttonText
                        mainImage {
                            alt
                            asset {
                                _id
                                gatsbyImageData(placeholder: DOMINANT_COLOR)
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <>
                    <HeroDiv url={imageUrlFor(data.sanityHeroComponent.mainImage).url()!}>
                        <HeroImage
                            image={getImage(data.sanityHeroComponent.mainImage.asset)!}
                            alt=""
                            imgStyle={{ filter: "opacity(90%)" }}
                        />
                        <HeroWrapper>
                            <HeroTextWrapper>
                                <HeroText>{data.sanityHeroComponent.heroMessage}</HeroText>
                                <HeroSubText>{data.sanityHeroComponent.heroText}</HeroSubText>
                                <span onClick={(e) => handleClick(e)}>
                                    <CtaBtn to={`/#kontakt`} title="kontakt oss">
                                        {data.sanityHeroComponent.buttonText}
                                    </CtaBtn>
                                </span>
                            </HeroTextWrapper>
                        </HeroWrapper>
                    </HeroDiv>
                </>
            )}
        />
    );
};

export default Hero;

const HeroDiv = styled.div<IHeroProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100%;
`;

const HeroImage = styled(GatsbyImage)<IHeroProps>`
    position: relative;
    z-index: -1;
    height: 100vh; // fallback and unsupported browsers
    height: -moz-available; // Mozilla-based browsers
    height: -webkit-fill-available; //  WebKit-based browsers
    height: fill-available;

    width: 100%;

    img {
        object-position: center bottom;
    }

    @media (max-width: 768px) {
        img {
            object-position: 70% top;
        }
    }
`;

const HeroWrapper = styled.div`
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding-left: 10vw;

    height: 100%;

    @media (max-width: 768px) {
        align-items: flex-start;
        justify-content: center;
        padding: 20vh 1vw;
    }

    @media (max-width: 450px) {
        padding: 5vh 1vw;
    }
`;

const HeroTextWrapper = styled.div`
    max-width: 50%;

    @media (max-width: 768px) {
        max-width: 85%;
    }
`;

const HeroText = styled.h1`
    font-size: var(--font-title1-size);
    line-height: var(--font-title1-line-height);
    margin: 2rem 0;
`;

const HeroSubText = styled.p`
    font-size: var(--font-title3-size);
    margin: 2rem 0;
`;

const CtaBtn = styled(AnchorLink)`
    font-size: var(--font-base-size);
    font-weight: 700;
    margin: 2rem 0;
    border-bottom: 1px solid;
    border-color: var(--theme-dark-text-on-background);
    padding-bottom: 3px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        color: var(--color-accent);
        border-color: var(--color-accent);
    }
`;
