import { graphql, StaticQuery } from "gatsby";
import React, { FC } from "react";
import styled from "styled-components";
import BgImg from "../images/mountains.jpg";

const About: FC = () => {
    return (
        <StaticQuery
            query={graphql`
                query AboutUs {
                    sanityAboutUsComponent(_id: { eq: "aboutUsComponent" }) {
                        aboutHeading
                        aboutText
                    }
                }
            `}
            render={(data) => (
                <>
                    <AboutContainer id="om">
                        <Img src={BgImg} />
                        <TextWrapper>
                            <AboutTitle>Vi er Fortress Digital</AboutTitle>

                            <AboutText>{data.sanityAboutUsComponent.aboutHeading}</AboutText>
                            <AboutText>{data.sanityAboutUsComponent.aboutText}</AboutText>
                        </TextWrapper>
                    </AboutContainer>
                </>
            )}
        />
    );
};

export default About;

const AboutContainer = styled.section`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0;

    position: relative;
`;

const Img = styled.img`
    max-height: 20vh;
    object-fit: cover;
    object-position: center 80%;
`;
const TextWrapper = styled.div`
    position: relative;

    max-width: 960px;
    padding: 0 1.5rem;
    margin: 0 auto;

    @media (min-width: 450px) {
        padding: 3rem;
    }
`;

const AboutTitle = styled.h2`
    font-size: var(--font-title2-size);
    margin: 2rem 0;
`;

const AboutText = styled.p`
    font-size: var(--font-base-size);
    margin: 1rem 0;
`;
