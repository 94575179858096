import { PaperPlane } from "@styled-icons/boxicons-solid/PaperPlane";
import { Laptop } from "@styled-icons/fluentui-system-filled/Laptop";
import { Lightbulb } from "@styled-icons/fluentui-system-filled/Lightbulb";
import { graphql, StaticQuery } from "gatsby";
import React, { FC } from "react";
import styled, { css } from "styled-components";

const Services: FC = () => {
    return (
        <StaticQuery
            query={graphql`
                query ServicesQuery {
                    sanityServicesComponent(_id: { eq: "servicesComponent" }) {
                        servicesTextOne
                        servicesTextTwo
                        servicesBlockOneTitle
                        servicesBlockOneDescription
                        servicesBlockTwoTitle
                        servicesBlockTwoDescription
                        servicesBlockThreeTitle
                        servicesBlockThreeDescription
                    }
                }
            `}
            render={(data) => (
                <>
                    <ServicesContainer id="tjenester">
                        <ServicesTitle>Tjenester</ServicesTitle>
                        <TextWrapper>
                            <ServicesText>{data.sanityServicesComponent.servicesTextOne}</ServicesText>
                            <ServicesText>{data.sanityServicesComponent.servicesTextTwo}</ServicesText>
                        </TextWrapper>
                        <BlocksWrapper>
                            <ServiceBlock>
                                <FirstIcon />
                                <BlockTitle>{data.sanityServicesComponent.servicesBlockOneTitle}</BlockTitle>
                                <BlockDescription>
                                    {data.sanityServicesComponent.servicesBlockOneDescription}
                                </BlockDescription>
                            </ServiceBlock>
                            {data.sanityServicesComponent.servicesBlockTwoTitle && (
                                <ServiceBlock>
                                    <SecondIcon />
                                    <BlockTitle>{data.sanityServicesComponent.servicesBlockTwoTitle}</BlockTitle>
                                    <BlockDescription>
                                        {data.sanityServicesComponent.servicesBlockTwoDescription}
                                    </BlockDescription>
                                </ServiceBlock>
                            )}
                            {data.sanityServicesComponent.servicesBlockThreeTitle && (
                                <ServiceBlock>
                                    <ThirdIcon />
                                    <BlockTitle>{data.sanityServicesComponent.servicesBlockThreeTitle}</BlockTitle>
                                    <BlockDescription>
                                        {data.sanityServicesComponent.servicesBlockThreeDescription}
                                    </BlockDescription>
                                </ServiceBlock>
                            )}
                        </BlocksWrapper>
                    </ServicesContainer>
                </>
            )}
        />
    );
};

export default Services;

const ServicesContainer = styled.section`
    display: flex;
    flex-direction: column;
    min-height: 40vh;
`;

const TextWrapper = styled.div``;

const ServicesTitle = styled.h2`
    font-size: var(--font-title2-size);
    margin: 2rem 0;
`;

const ServicesText = styled.p`
    font-size: var(--font-base-size);
    margin: 1rem 0;
`;

const BlocksWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 2rem 0;
    /* min-height: 30vh; */
    @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const ServiceBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 1rem 0;

    max-width: 15rem;
    text-align: center;
    height: 100%;
    flex: 1;
`;

const sharedIconStyles = css`
    height: 6rem;
    max-width: 6rem;
    margin-bottom: 1rem;

    @media (min-width: 768px) {
        height: 7rem;
        max-width: 8rem;
        margin-bottom: 1rem;
    }
`;

const FirstIcon = styled(Laptop)`
    ${sharedIconStyles}
`;

const SecondIcon = styled(Lightbulb)`
    ${sharedIconStyles}
`;

const ThirdIcon = styled(PaperPlane)`
    ${sharedIconStyles}
`;

const BlockTitle = styled.h3`
    margin-bottom: 1rem;
`;

const BlockDescription = styled.p`
    /* text-align: center; */
`;
