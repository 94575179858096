import React, { FC } from "react";
import styled from "styled-components";

const Form: FC = () => {
    const handleSubmit = () => {
        window.dataLayer.push({
            event: "formSubmission",
            location: "contactForm",
        });
    };

    return (
        <>
            <FormComponent>
                <StyledForm
                    name="contact-fortress-v1"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    // action="/thank-you-page"
                    onSubmit={() => handleSubmit()}
                >
                    <input type="hidden" name="form-name" value="contact-fortress-v1" />
                    <p hidden>
                        <label>
                            Denne input skal ikke fylles ut: <input type="hidden" name="bot-field" />
                        </label>
                    </p>
                    <FormLabel htmlFor="Navn">
                        Navn <span>*</span>
                    </FormLabel>
                    <FormInput required type="text" name="Navn" />
                    <FormLabel htmlFor="E-post">
                        E-post <span>*</span>
                    </FormLabel>
                    <FormInput required type="email" name="E-post" />
                    <FormLabel htmlFor="Telefon">Telefonnummer</FormLabel>
                    <FormInput type="phone" name="Telefon" />
                    <FormLabel htmlFor="Melding">
                        Melding <span>*</span>
                    </FormLabel>
                    <FormTextArea required name="Melding" />
                    <SubText>Alle felt med * må fylles ut.</SubText>
                    {/* <Error>
                        <p>Error message.</p>
                    </Error> */}
                    <FormButton type="submit">Send</FormButton>
                </StyledForm>
            </FormComponent>
        </>
    );
};

export default Form;

const FormComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    width: 80%;
    max-width: 100%;
    background: var(--color-light-accent);
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
`;

const FormInput = styled.input`
    height: 2rem;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 2px;
    font-size: var(--font-base-size);
`;

const FormLabel = styled.label`
    font-size: var(--font-base-size);

    span {
        font-size: var(--font-small-size);
    }
`;

const FormTextArea = styled.textarea`
    height: 10rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 2px;
    font-size: var(--font-base-size);
    font-family: var(--font-family-sans);
`;

const FormButton = styled.button`
    display: block;
    font-size: var(--font-large-size);
    margin: 2rem 0;
    background-color: var(--button-color);
    color: #fff;
    border: none;
    border-radius: 2px;
    height: 3rem;
    padding: 0 1rem;
    transition: var(--transition);

    &:hover {
        background-color: var(--button-color-hover);
        cursor: pointer;
    }
`;

// const Error = styled.div``;

const SubText = styled.p`
    font-size: var(--font-small-size);
`;
