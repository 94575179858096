import { graphql, StaticQuery } from "gatsby";
import React, { FC } from "react";
import styled from "styled-components";
import Form from "./Form";

const Contact: FC = () => {
    return (
        <StaticQuery
            query={graphql`
                query ContactQuery {
                    sanityCompanyInfo(_id: { eq: "companyInfo" }) {
                        companyName
                        contactPerson
                        contactPhone
                        contactEmail
                        companyAddress
                        companyArea
                        facebookUrl
                    }
                }
            `}
            render={(data) => (
                <>
                    <ContactSection id="kontakt">
                        <ContactContainer>
                            <ContactInfoContainer>
                                <ContactTitle>Ta kontakt med oss!</ContactTitle>
                                <ContactText>
                                    Vi vil gjerne høre fra deg hvis det er noe du lurer på, eller om det er noe annet vi
                                    kan gjøre for deg.
                                </ContactText>
                                <ContactText>
                                    Ring oss på{" "}
                                    <ContactInfo href={`tel:${data.sanityCompanyInfo.contactPhone}`}>
                                        <span>{data.sanityCompanyInfo.contactPhone}</span>
                                    </ContactInfo>{" "}
                                    eller send en e-post til {}
                                    <ContactInfo
                                        href={`mailto:${data.sanityCompanyInfo.contactEmail}?subject=Kontakt%20Fortress%20Digital`}
                                    >
                                        <span>{data.sanityCompanyInfo.contactEmail}</span>
                                    </ContactInfo>
                                    {} .
                                </ContactText>
                                <ContactText></ContactText>
                                <ContactText>
                                    Alternativt kan du fylle ut kontaktskjemaet vårt, så svarer vi deg på e-post eller
                                    telefon.
                                </ContactText>
                            </ContactInfoContainer>
                            <ContactInfoContainer>
                                <Form />
                            </ContactInfoContainer>
                        </ContactContainer>
                    </ContactSection>
                </>
            )}
        />
    );
};

export default Contact;

const ContactSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    min-height: 50vh;
    background: var(--color-light-accent);
`;

const ContactContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    margin: 2rem 0;
    padding: 0 3rem;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding: 0 0.5rem;
    }
`;

const ContactInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;

    :nth-child(1) {
        margin-bottom: 2rem;
    }
`;

const ContactTitle = styled.h2`
    font-size: var(--font-title2-size);
    margin-bottom: 1.5rem;
`;

const ContactText = styled.p`
    margin: 0.5rem 0;
    line-height: 1.7rem;
`;

const ContactInfo = styled.a`
    border-bottom: 1px solid;
    border-color: var(--theme-dark-text-on-background);
    padding-bottom: 3px;
    text-decoration: none;

    span {
        white-space: nowrap;
    }

    &:hover {
        cursor: pointer;
        color: var(--color-accent);
        border-color: var(--color-accent);
        font-weight: bolder;
    }
`;
