import { graphql, StaticQuery } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React, { FC } from "react";
import styled from "styled-components";

const Customers: FC = () => {
    return (
        <StaticQuery
            query={graphql`
                query customerQuery {
                    allSanityCustomer(sort: { fields: order, order: ASC }) {
                        nodes {
                            name
                            logo {
                                alt
                                asset {
                                    _id
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <>
                    <AboutContainer>
                        <ContainerWrap>
                            <SectionTitle>Våre kunder</SectionTitle>
                            <CustomersWrap>
                                {data.allSanityCustomer.nodes.map((c: any) => (
                                    <CustomerLogo key={c.logo.asset._id} {...c.logo} />
                                ))}
                            </CustomersWrap>
                        </ContainerWrap>
                    </AboutContainer>
                </>
            )}
        />
    );
};

export default Customers;

const AboutContainer = styled.section``;

const ContainerWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionTitle = styled.h2`
    font-size: var(--font-title2-size);
`;

const CustomersWrap = styled.div`
    min-height: 5vh;
    max-width: 100%;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 2rem;
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }

    @media (max-width: 450px) {
        flex-direction: column;
    }
`;

const CustomerLogo = styled(Image)`
    object-fit: contain;
    min-height: 4rem;
    max-height: 7rem;
    max-width: 8rem;
    padding: 1rem 0.5rem;
    flex: auto;
`;
