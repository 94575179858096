import { graphql } from "gatsby";
import React, { FC } from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Customers from "../components/Customers";
import GraphQLErrorList from "../components/graphql-error-list";
import Hero from "../components/Hero";
import Seo from "../components/SEO";
import Services from "../components/Services";
import Layout from "../containers/layout";

export const query = graphql`
    query IndexPageQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
            metaImage {
                alt
                asset {
                    _id
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
            }
        }
    }
`;

const IndexPage: FC<{ data: any; errors: any }> = (props) => {
    const { data, errors } = props;

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        );
    }

    const site = (data || {}).site;

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        );
    }

    return (
        <Layout>
            <Seo
                title={site.title}
                description={site.description}
                keywords={site.keywords}
                image={site.metaImage.asset}
            />
            <Hero />
            <Services />
            <About />
            <Customers />
            <Contact />
        </Layout>
    );
};

export default IndexPage;
